import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';


const Selection = lazy(() => import('../../app_components/yearBookSelection/Selection'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="getYearBookStudentDetail" element={<Selection />} />
    </Routes>
  );
}

export default PagesRoute;
