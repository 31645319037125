import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Drawer, Modal, Switch } from 'antd';
import UilSignout from '@iconscout/react-unicons/icons/uil-sign-out-alt';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilMoon from '@iconscout/react-unicons/icons/uil-moon';
import UilSun from '@iconscout/react-unicons/icons/uil-sun';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Heading from '../../heading/heading';
import { changeLayoutMode } from '../../../redux/themeLayout/actionCreator';
import { logout } from '../../../redux/authenticator/authenticationSlice';
import { removeToken } from '../../../APIServices/TokenService';
import ContactUs from '../../../app_components/ContactUs/ContactUs';
import Disclaimer from '../../../app_components/disclaimer/Disclaimer';
import { post } from '../../../config/dataService/dataService';
import SignOutWarning from '../../../app_components/Warning/signOutWarning';
import PasswordChange from '../../../app_components/passwordChange/PasswordChange';

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const [contactUsModal, setContactUsModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [passwordChangeModal, setPasswordChangeModal] = useState(false);

  const [darkMode, setDarkMode] = useState(false);
  const [state, setState] = useState({
    flag: 'en',
  });


  const ContactUsModalOpen = () => {
    setContactUsModal(true);
  };

  const ContactUsModalClose = () => {
    setContactUsModal(false);
  };

  const passwordChangeModalClose = () => {
    setPasswordChangeModal(false);
  }


  const firstName = localStorage.getItem('FIRST_NAME') || '';
  const lastName = localStorage.getItem('LAST_NAME') || '';
  const Fullname = `${firstName} ${lastName}`.trim();


  const darkmodeActivated = () => {
    document.body.classList.add('dark-mode');
  };

  const darkmodeDiactivated = () => {
    document.body.classList.remove('dark-mode');
  };

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    if (!darkMode) {
      darkmodeActivated();
      changeLayout('darkMode');
    } else {
      darkmodeDiactivated();
      changeLayout('lightMode');
    }
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">Abdullah Bin Talha</Heading>
            <p>UI Expert</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="#">
              <UilUser /> Profile
            </Link>
          </li>
          <li>
            <Link to="#">
              {darkMode ? <UilSun /> : <UilMoon />}
              <Switch
                checkedChildren="Light Mode"
                unCheckedChildren="Dark Mode"
                checked={darkMode}
                onChange={toggleDarkMode}
              />
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={() => setLogoutModal(true)} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value, e) => {
    e.preventDefault();
    setState({
      ...state,
      flag: value,
    });
    i18n.changeLanguage(value);
  };

  const country = (
    <NavAuth>
      <Link onClick={(e) => onFlagChangeHandle('en', e)} to="#">
        <img src={require('../../../static/img/flag/en.png')} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('esp', e)} to="#">
        <img src={require('../../../static/img/flag/esp.png')} alt="" />
        <span>Spanish</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('ar', e)} to="#">
        <img src={require('../../../static/img/flag/ar.png')} alt="" />
        <span>Arabic</span>
      </Link>
    </NavAuth>
  );

  return (
    <>
      <InfoWraper>
        <div className="signoutStyle">
          <Link
            className="user-dropdwon__bottomAction"
            to="https://barksdalevasstorage.blob.core.windows.net/helpfile/Barksdale New Portal Quick Reference Guide.pdf"
            target="_blank"
            rel="noopener"
          >
            <span className="ninjadash-nav-actions__author--name">Help | </span>
          </Link>
        </div>
        <div className="signoutStyle">
          <Link className="user-dropdwon__bottomAction" onClick={() => setContactUsModal(true)} to="#">
            <span className="ninjadash-nav-actions__author--name">Contact Us | </span>
          </Link>
        </div>
        <div className="signoutStyle">
          <Link className="user-dropdwon__bottomAction" onClick={() => setPasswordChangeModal(true)} to="#">
            <span className="ninjadash-nav-actions__author--name">Change Password | </span>
          </Link>
        </div>

        <div onClick={() => setLogoutModal(true)} style={{ cursor: 'pointer' }} className="ninjadash-nav-actions__item ninjadash-nav-actions__author signoutStyle">
          <Link className="user-dropdwon__bottomAction" to="#">
            <span className="ninjadash-nav-actions__author--name">{Fullname}</span>
          </Link>
          <Link className="user-dropdwon__bottomAction" to="#">
            <UilSignout />
          </Link>
        </div>
      </InfoWraper>

      <Modal title="Change Password" open={passwordChangeModal} footer={null} closable={true} width={700} onCancel={passwordChangeModalClose} maskClosable={false}>
        <PasswordChange onCancel={passwordChangeModalClose} modalopen={passwordChangeModal} />
      </Modal>

      <Modal title="Contact Us" open={contactUsModal} footer={null} closable={true} width={1200} onCancel={ContactUsModalClose} maskClosable={false}>
        <ContactUs onCancel={ContactUsModalClose} modalopen={contactUsModal} />
      </Modal>

      <Modal title="Ready To Leave?" open={logoutModal} footer={null} width={550} onCancel={() => setLogoutModal(false)}>
        <SignOutWarning onClose={() => setLogoutModal(false)} />
      </Modal>
    </>
  );
});

export default AuthInfo;
