
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fromStaff : null
};


const staffstateSlice = createSlice({
  name: 'staffs',
  initialState,
  reducers: {
    setFromStaff: (state, action) => {
      state.fromStaff = action.payload;
    }
  },
});

export const { setFromStaff } = staffstateSlice.actions;

export default staffstateSlice.reducer;


