import React from 'react';
import { Modal, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../../redux/modal/modalSlice';

const SessionExpiredModal = () => {
    const dispatch = useDispatch();
    const isModalVisible = useSelector((state) => state.modal.isModalVisible);

    const handleOk = () => {
        dispatch(hideModal());
        window.localStorage.clear();
        window.location.href = '/';
    };

    return (
        <Modal
            title="Session Expired"
            closable={false}  // Remove the close button
            visible={isModalVisible}
            footer={[
                <Button key="ok" type="primary" onClick={handleOk}>
                    OK
                </Button>,
            ]}
        >
            <p>Your session has expired. Please log in again.</p>
        </Modal>
    );
};

export default SessionExpiredModal;
