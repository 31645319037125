// src/api/apiClient.js
import axios from 'axios';
import { getToken } from '../../APIServices/TokenService';
import { MessageType, showNotification } from '../../app_components/common/CommonStyle';
import { showModal } from '../../redux/modal/modalSlice';
import store from '../../redux/store';
const API_ENDPOINT = `${process.env.REACT_APP_APIURL_ENDPOINT}/api`;

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

const authHeader = () => {
  const token = getToken();
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }
  return {};
};

async function get(path = '') {
  try {
    const response = await client({
      method: 'GET',
      url: path,
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

async function post(path = '', data = {}, optionalHeader = {}) {
  try {
    const response = await client({
      method: 'POST',
      url: path,
      data,
      headers: path === 'Login' ? {} : { ...authHeader(), ...optionalHeader },
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

async function postBody(path = '', data = {}, optionalHeader = {}) {
  try {
    const response = await client({
      method: 'POST',
      url: path,
      body: data,
      headers: path === 'Login' ? {} : { ...authHeader(), ...optionalHeader },
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

async function put(path = '', data = {}, optionalHeader = {}) {
  try {
    const response = await client({
      method: 'PUT',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

async function del(path = '', optionalHeader = {}) {
  try {
    const response = await client({
      method: 'DELETE',
      url: path,
      headers: { ...authHeader(), ...optionalHeader },
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

function handleResponse(response) {
  return response.data;
}

function handleError(error) {
  if (error.response) {
    const { status } = error.response;
    console.log(`Error Status: ${status}`);
    if (status === 401) {
      logout();
    } else if (status === 405) {
      console.log('Method Not Allowed (405) - No action taken.');
      return {};
    }
  }

  return Promise.reject(error);
}


// function handleError(error) {
//   if (error.response) {
//     const { status } = error.response;
//     console.log(`Error Status: ${status}`);
//     if (status === 401) {
//       window.location.href = '/'
//       store.dispatch(showModal());  // Dispatch the showModal action
//     } else if (status === 405) {
//       console.log('Method Not Allowed (405) - No action taken.');
//       return {};
//     }
//   }

//   return Promise.reject(error);
// }

function logout() {
  window.localStorage.clear();
  window.location.href = '/';
}

client.interceptors.request.use((config) => {
  const requestConfig = config;
  const { headers } = config;
  const authHeaders = authHeader();
  requestConfig.headers = { ...headers, ...authHeaders };
  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response) {
      const { status } = response;
      console.log(`Interceptor Error Status: ${status}`);
      if (status === 401) {
        logout();
      } else if (status === 405) {
        console.log('Interceptor - Method Not Allowed (405) - No action taken.');
        return Promise.resolve({});
      }
    }
    return Promise.reject(error);
  },
);

// client.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const { response } = error;
//     if (response) {
//       const { status } = response;
//       console.log(`Interceptor Error Status: ${status}`);
//       if (status === 401) {
//         store.dispatch(showModal());  // Dispatch the showModal action
//         return Promise.resolve({});
//       } else if (status === 405) {
//         console.log('Interceptor - Method Not Allowed (405) - No action taken.');
//         return Promise.resolve({});
//       }
//     }

//     return Promise.reject(error);
//   },
// );

export { get, post, put, del, handleResponse, handleError, logout, postBody };
