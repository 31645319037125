import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// const Users = lazy(() => import('../../container/users/Users'));
const AddUser = lazy(() => import('../../app_components/user/AddUsers'));
const NotFound = lazy(() => import('../../container/pages/404'));
// const AddStudent =lazy(() => import('../../app_components/student/AddStudent'));
const RoleRights = lazy(() => import('../../app_components/user/Role/RoleRights'));
const DemoUser = lazy(() => import('../../app_components/user/DemoUser'))
// const RolePermission = lazy(() => import('../../app_components/user/Role/RolePermission'));
const CustomRolePermission = lazy(() => import('../../app_components/user/Role/CustomRolePermission'))
const CommonRolePermission = lazy(() => import('../../app_components/user/Role/CommonRolePermission')) 


function PagesRoute() {
  return (
    <Routes>
      {/* <Route path="/*" element={<User/>} /> */}
      <Route path="add-user/*" element={<AddUser/>} />
      <Route path="role-rights" element={<RoleRights/>} />
      <Route path="custom-rolepermission/:id" element={<CustomRolePermission/>}/>
      <Route path="demo-user" element={<DemoUser/>} />
      <Route path="role-permission/:id" element={<CommonRolePermission/>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
