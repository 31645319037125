import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react';

const parseJSON = (value) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
};

const initialState = {
  data: {
    userId: parseJSON(window.localStorage.getItem('USER_ID')),
    firstName: parseJSON(window.localStorage.getItem('FIRST_NAME')),
    lastName: parseJSON(window.localStorage.getItem('LAST_NAME')),
    role: parseJSON(window.localStorage.getItem('ROLE')),
    schoolId: parseJSON(window.localStorage.getItem('SCHOOL_ID')),
    districtId: parseJSON(window.localStorage.getItem('DISTRICT_ID')),
    email: parseJSON(window.localStorage.getItem('EMAIL')),
    isShowDisclaimer: parseJSON(window.localStorage.getItem('SHOW_DISCLAIMER')),
    schoolName: window.localStorage.getItem('SCHOOL_NAME'),
    userRoleLevelName: window.localStorage.getItem('USER_ROLE_LEVEL_NAME'),
    roleName: window.localStorage.getItem('ROLE_NAME'),
  },
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      window.localStorage.setItem('USER_ID', JSON.stringify(action.payload.userId));
      window.localStorage.setItem('FIRST_NAME', action.payload.firstName);
      window.localStorage.setItem('LAST_NAME', action.payload.lastName);
      window.localStorage.setItem('ROLE', JSON.stringify(action.payload.userRoleLevel));
      window.localStorage.setItem('SCHOOL_ID', JSON.stringify(action.payload.schoolId));
      window.localStorage.setItem('DISTRICT_ID', JSON.stringify(action.payload.districtId));
      window.localStorage.setItem('DIVISION_NAME', JSON.stringify(action.payload.divisionName));
      window.localStorage.setItem('DISPLAY_NAME', JSON.stringify(action.payload.displayname));
      window.localStorage.setItem('EMAIL', action.payload.email);
      window.localStorage.setItem('SHOW_DISCLAIMER', JSON.stringify(action.payload.isShowDisclaimer));
      window.localStorage.setItem('STUDENT_WARNING', true);
      window.localStorage.setItem('STAFF_WARNING', true);
      window.localStorage.setItem('SCHOOL_NAME', action.payload.schoolName);
      window.localStorage.setItem('USER_ROLE_LEVEL_NAME', action.payload.userRoleLevelName);
      window.localStorage.setItem('ROLE_NAME',action.payload.roleName);


      state.data = {
        userId: action.payload.userId,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        role: action.payload.userRoleLevel,
        schoolId: action.payload.schoolId,
        districtId: action.payload.districtId,
        displayname : action.payload.displayname,
        email: action.payload.email,
        disclaimer: action.payload.isShowDisclaimer,
        schoolName: action.payload.schoolName,
        divisionName: action.payload.divisionName,
        userRoleLevelName: action.payload.userRoleLevelName,
        roleName: action.payload.roleName,
      };
    },
    showData: (state) => {
    },
    logout: (state) => {
      state.data = {
        userId: null,
        firstName: null,
        lastName: null,
        role: null,
        schoolId: null,
        districtId: null,
        email: null,
        schoolName: null,
        divisionName: null,
        userRoleLevelName: null,
        roleName: null,
      };

      window.localStorage.removeItem('USER_ID');
      window.localStorage.removeItem('FIRST_NAME');
      window.localStorage.removeItem('LAST_NAME');
      window.localStorage.removeItem('ROLE');
      window.localStorage.removeItem('SCHOOL_ID');
      window.localStorage.removeItem('DISTRICT_ID');
      window.localStorage.removeItem('DISPLAY_NAME');
      window.localStorage.removeItem('DIVISION_NAME');
      window.localStorage.removeItem('EMAIL');
      window.localStorage.removeItem('SHOW_DISCLAIMER');
      window.localStorage.removeItem('RP');
      window.localStorage.removeItem('STUDENT_WARNING');
      window.localStorage.removeItem('SCHOOL_WARNING');
      window.localStorage.removeItem('SCHOOL_NAME');
      window.localStorage.removeItem('USER_ROLE_LEVEL_NAME');
      window.localStorage.removeItem('ROLE_NAME');
    },
  },
});

export const { loginSuccess, logout, showData } = authenticationSlice.actions;

export default authenticationSlice.reducer;
