
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fromStudent : null
};


const studentstateSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    setFromStudent: (state, action) => {
      state.fromStudent = action.payload;
    }
  },
});

export const { setFromStudent } = studentstateSlice.actions;

export default studentstateSlice.reducer;


