import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';


const NotFound = lazy(() => import('../../container/pages/404'));
const AddStaff =lazy(() => import('../../app_components/Staff/AddStaff'));


function PagesRoute() {
  return (
    <Routes>
      
      <Route path="add-stff" element={<AddStaff/>} />
      
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
