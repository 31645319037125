import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';


const SchoolExcludeEvent = lazy(() => import('../../app_components/division/SchoolExcludeEvent'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="school-exclude-event/:id" element={<SchoolExcludeEvent />} />
    </Routes>
  );
}

export default PagesRoute;
