import { notification } from 'antd';
 
export const MessageType = {
  Success: 'success',
  Warning: 'warning',
  Error :'error',
  CatchError:"catcherror"
};

export const showNotification = (Type, message) => {
  if (Type === "catcherror") {
    if (message === "") {
      message = "Something went to Wrong,Please Try again!.";
    }
    Type = MessageType.Error;
  }
  notification[Type]({
    message: Type,
    description: message
  });
};

