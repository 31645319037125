// reducers/schoolReducer.js
import { SET_SELECTED_SCHOOL_ID } from './actions';

const initialState = null; // Initial state for selectedSchoolId

const schoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_SCHOOL_ID:
      return action.payload;
    default:
      return state;
  }
};

export  {schoolReducer};
