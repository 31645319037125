import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const AddTemplate =lazy(() => import('../../app_components/TemplateBuilder/AddCommonTemplateBuilder'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="add-template" element={<AddTemplate/>}  />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
