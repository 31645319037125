// Disclaimer.js
import React from 'react';
import { Spin, Row, Col, Button } from 'antd';

const Disclaimer = ({ handleAccept }) => {
  return (
      <Row align="middle">
        <Col span={24}>
          <p>Confidentiality Statement: <br />
          The information contained in the Portal is confidential and privileged. Any
          unauthorized disclosure, use, distribution, or reproduction of this data including any data or images is
          strictly prohibited and may violate the law, including protections of student images and information. By
          clicking Accept, you are agreeing to these confidentiality terms and promising not to disclose, use,
          distribute or reproduce any confidential information other than for the legitimate purposes of Barksdale
          School Portraits and its affiliated companies.</p>
        </Col>
        <Col span={24} style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button type="primary" onClick={handleAccept}>
            Accept
          </Button>
        </Col>
      </Row>
  );
};

export default Disclaimer;
