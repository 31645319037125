import { ConfigProvider, Spin } from 'antd';
import 'antd/dist/antd.less';
import React, { useEffect, useState, lazy } from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import config from './config/config';
import {store, persistor} from './redux/store';

import { PersistGate } from 'redux-persist/integration/react';


import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import SessionExpiredModal from './app_components/modal/SessionExpiredModal';
import useRemoveText from './utility/useRemoveText';

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config;

function ProviderConfig() {
  
  const { rtl, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
    };
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>

        <Router basename={process.env.PUBLIC_URL}>
          {loading ? (
            <div className="spin">
              <Spin />
            </div>
          ) : (
            <Routes>
              <Route path="/admin/*" element={<Admin />} />
              <Route path="/*" element={<Auth />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          )}
        </Router>

      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
      <PersistGate loading={null} persistor={persistor}>
      <SessionExpiredModal />
      </PersistGate>
    </Provider>
  );
}

export default App;