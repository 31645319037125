import CryptoJS, { enc } from 'crypto-js';

const getToken = () => {
  const token = decryptToken(window.localStorage?.getItem("TOKEN"))
  return token ? token : null
};

const decryptToken = (token) => {
  if (!token) return null;
  const bytes = CryptoJS.AES.decrypt(token, process.env.REACT_APP_ENCRYPTION_KEY);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
};

const saveToken = (token) => {
  const cryptedText = CryptoJS.AES.encrypt(token, process.env.REACT_APP_ENCRYPTION_KEY);
  window.localStorage?.setItem('TOKEN', cryptedText);
};

const removeToken = () => {
  window.localStorage?.removeItem('TOKEN');
};

const SchoolListUploadService = async(url, values,selectedFile,headerMapping,optionalScreenMapping) => {

  const formData = new FormData();
  formData.append('ImportType', values.ImportType);
  formData.append('FlyerHeaderTemplateId', values.FlyerHeaderTemplateId);
  formData.append('photoEventCategory', values.PhotoEventCategory);
  formData.append('PhotoEventId', values.PhotoEventId)
  formData.append('FileName', selectedFile.name);
  formData.append('Message', values.Message);
  formData.append('headerMappingFromScreen',{});
  formData.append('optionalheaderMappingFromScreen',{});
  formData.append('headerMapping',{});
  formData.append('optionalheaderMapping',{});
  formData.append('PostedFile', selectedFile);


  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_APIURL_ENDPOINT}/api/` + url, requestOptions);
  let result = [];
  if (response.ok) {
    result = await response.json();
  } else {
    result = [];
  }

  return result;
}

const ImportFromLocalService = async (url, values, selectedFile) => {
  const formData = new FormData();
  formData.append('ImportCatergory', values.ImportCatergory);
  formData.append('PostedFile', selectedFile);

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_APIURL_ENDPOINT}/api/` + url, requestOptions);
  let result = [];
  if (response.ok) {
    result = await response.json();
  } else {
    result = [];
  }

  return result;
}

const MissingDataUploadService = async(url, formData) => {

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_APIURL_ENDPOINT}/api/` + url, requestOptions);
  let result = [];
  if(response.ok){
    result = await response.json();
  }else{
    result = [];
  }
  return result;
}

export { getToken, decryptToken, saveToken, removeToken, SchoolListUploadService, ImportFromLocalService, MissingDataUploadService };
