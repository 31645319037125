/* eslint-disable react-hooks/exhaustive-deps */

import * as Icons from '@iconscout/react-unicons';
import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
// import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get } from '../config/dataService/dataService';

function MenuItems({ toggleCollapsed }) {
  // const { t } = useTranslation();
  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const urls = {
    getMenuFilter: 'RolePermission/GetMenuByUserId',
  };

  const path = '/admin';
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []
  );
  const [items, setItems] = useState([]);

  useEffect(() => {
    const userId = window.localStorage.getItem('USER_ID')
    fetchMenu(userId); // Fetch menu items when component mounts
  }, []);

  const fetchMenu = async (UserId) => {
    // Assuming you have a function to fetch menu data from the API
    // You may need to replace 'GetAllDivisionFilter' with your actual API call function
    await get(`${urls.getMenuFilter}?id=${UserId}`).then((response) => {
      // const filteredResponse = response.data.filter(item => !(item.IsSubMenu && (item.subMenu == null)));
      const filteredResponse = response.data.filter(item => {
        // If the item is a submenu, it should have non-empty subMenu array
        if (item.IsSubMenu) {
          return item.subMenu && item.subMenu.length > 0;
        }
        // Otherwise, include the item
        return true;
      });

      const menuData = filteredResponse.map((item) => {
        // Convert database fields to desired structure

        const convertedItem = {
          label: item.MenuName,
          key: item.Id.toString(),
          route: item.RouteAction,
          icon: Icons[item.MenuIcon],
          isSubMenu: item.isSubMenu,
          type: item.RouteAction ? 'item' : 'submenu',
        };


        // Handle sub-menu (children)
        if (item.subMenu && item.subMenu.length > 0) {
          const childrenArray = JSON.parse(item.subMenu);
          convertedItem.children = childrenArray.map((subMenuItem) => ({
            label: subMenuItem.MenuName,
            key: subMenuItem.Id.toString(),
            route: subMenuItem.RouteAction,
            icon: subMenuItem.MenuIcon,
            type: 'item', // Assuming all sub-menu items are of type 'item'
          }));
        }
        else {
          convertedItem.children = [];
        }
        return convertedItem;
      });
      // Process menu data and set the state
      const processedItems = processMenuData(menuData);

      // const staticMenuItems = [
      //   getItem(
      //     <NavLink onClick={toggleCollapsed} to={`${path}/importfromlocal`}>
      //      Import From Local
      //     </NavLink>,
      //     'export-paps',
      //     <Icons.UilExport />,
      //     null,
      //     'item'
      //   ),
      // ];
      setItems([...processedItems]);
    }).catch((error) => {
      console.error('Error fetching menu:', error);
    });
  };

  const processMenuData = (menuData) => {
    // Process menu data as needed, create items array
    // You may need to adapt this based on the structure of your menu data
    const processedItems = menuData.map((menuItem) => {

      if (menuItem.children && menuItem.children.length > 0) {
        // If there are children, recursively process them
        const processedChildren = processMenuData(menuItem.children);
        // Return the parent item with processed children
        return getItem(
          menuItem.label,
          menuItem.key,
          <menuItem.icon />,
          processedChildren,
          menuItem.type
        );
      } else {
        // If no children, simply return the item
        return getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/${menuItem.route}`}>
            {menuItem.label}
          </NavLink>,
          menuItem.key,
          <menuItem.icon />,
          null,
          menuItem.type
        );
      }
    });
    return processedItems;
  };

  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1
              ? 'home'
              : mainPathSplit.length === 2
                ? mainPathSplit[1]
                : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
