import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// const Users = lazy(() => import('../../container/users/Users'));
// const AddUser = lazy(() => import('../../container/users/AddUsers'));
const NotFound = lazy(() => import('../../container/pages/404'));
const AddStudent =lazy(() => import('../../app_components/student/AddStudent'));
// const RoleRights = lazy(() => import('../../app_components/user/RoleRights'))

function PagesRoute() {
  return (
    <Routes>
      <Route path="add-student/:id?" element={<AddStudent/>}  />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
