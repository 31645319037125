import React from 'react';
import { Row, Col, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/authenticator/authenticationSlice';
import { removeToken } from '../../APIServices/TokenService';
import { useNavigate } from 'react-router-dom';

const SignOutWarning = ({ onClose }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = (e) => {
        e?.preventDefault();
        dispatch(logout());
        removeToken();
        navigate('/');
    };

    return (
        <Row align="middle" justify="center" style={{ padding: '20px' }}>
            <Col span={24}>
                <p style={{ fontWeight: '600', textAlign: 'end', fontSize: '16px' }}>
                    Select "Logout" if you are ready to end your current session.
                </p>
            </Col>
            <Col span={24} style={{ textAlign: 'end', marginTop: '20px' }}>
                <Button type="default" onClick={onClose} style={{ marginRight: '10px' }}>
                    Cancel
                </Button>
                <Button type="primary" onClick={handleLogout}>
                    Logout
                </Button>
            </Col>
        </Row>
    );
};

export default SignOutWarning;
