import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Col, Form, Input, Row, Spin } from 'antd'
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilMail from '@iconscout/react-unicons/icons/uil-envelope';
import UilSubject from '@iconscout/react-unicons/icons/uil-bars';
import UilSchool from '@iconscout/react-unicons/icons/uil-building';
import TextArea from 'antd/lib/input/TextArea';
import { showNotification, MessageType } from '../common/CommonStyle';
import { post } from '../../config/dataService/dataService';

const ContactUs = ({ onCancel }) => {

  const urls = {
    getcontactus: 'ContactUs/AddContactUs'
  };
  const firstName = localStorage.getItem('FIRST_NAME') || '';
  const lastName = localStorage.getItem('LAST_NAME') || '';
  const Fullname = `${firstName} ${lastName}`.trim();
  const EmailId = localStorage.getItem('EMAIL').trim();
  const SchoolName = localStorage.getItem('SCHOOL_NAME');
  const [loading, setLoading] = useState(false);
  const [editSchoolName, setEditSchoolName] = useState(false);
  const [form] = Form.useForm();

  const token = useSelector(state => state.token);

  const FIRST_NAME = localStorage.getItem('FIRST_NAME') || '';
  const LAST_NAME = localStorage.getItem('LAST_NAME') || '';
  let fullname = FIRST_NAME + ' ' + LAST_NAME;

  const handleSaveorUpdate = async (values) => {
    try {
      setLoading(true);
      values.contactName = Fullname;
      values.contactEmail = EmailId;
      const result = await post(urls.getcontactus, values);

      if (result.flag) {
        form.resetFields();
        showNotification(MessageType.Success, result.message);
        const timeout = setTimeout(() => {
          onCancel();
        }, 1500);
      }
      else {
        showNotification(MessageType.Error, result.message);
      }
    } catch (errorInfo) {
      showNotification(MessageType.CatchError, "");
    }
    setLoading(false);
  };


  const handleCancel = () => {
    form.resetFields();
    onCancel();
  }

  useEffect(() => {
    console.log(SchoolName);
    setLoading(true)
    if (SchoolName && SchoolName !== null) {
      setEditSchoolName(true);
    } else {
      setEditSchoolName(false);
    }
    setLoading(false)
  }, []);

  return (
    <Spin spinning={loading}>
      <Form onFinish={handleSaveorUpdate} form={form} className="contact_style">
        <Row align="middle">
          <Col lg={3} md={9} xs={24}>
            <label className='contactus'>Name</label>
          </Col>
          <Col lg={21} md={15} xs={24}>
            <Form.Item name="contactName" className="ninjadash-icon-left">
              <Input prefix={<UilUser />} defaultValue={Fullname} readonly="readonly" />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={3} md={9} xs={24}>                                                                 
            <label className='contactus' htmlFor="email">Email Address</label>
          </Col>
          <Col lg={21} md={15} xs={24}>
            <Form.Item className="ninjadash-icon-left" name="contactEmail">
              <Input prefix={<UilMail />} defaultValue={EmailId}   readonly="readonly"/>
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={3} md={9} xs={24}>
            <label className="required-label contactus">Subject</label>
          </Col>
          <Col lg={21} md={15} xs={24}>
            <Form.Item name="contactTitle"
              rules={[{ required: true, message: 'Subject is required' }]}>
              <Input prefix={<UilSubject />} autoComplete='off' />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={3} md={9} xs={24}>
            <label className='contactus' htmlFor="email">School Name</label>
          </Col>
          <Col lg={21} md={15} xs={24}>
            <Form.Item className="ninjadash-icon-left" name="contactSchoolName">
              <Input prefix={<UilSchool />} disabled={!editSchoolName} defaultValue={editSchoolName ? SchoolName : undefined} />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={3} md={9} xs={24}>
            <label className='contactus' htmlFor="password">Staff/Student ID</label>
          </Col>
          <Col lg={21} md={15} xs={24}>
            <Form.Item name="contactId">
              <Input prefix={<UilUser />} />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={3} md={9} xs={24}>
            <label className="required-label contactus" htmlFor="password">Message</label>
          </Col>
          <Col lg={21} md={15} xs={24}>
            <Form.Item name="contactMessage"
              rules={[{ required: true, message: 'Message is required' }]}>
              <TextArea rows={2} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ justifyContent: "end" }}>
          <div className="ninjadash-form-action">
            <Button className="contactBtn1" type="primary" size="large" htmlType="submit">
              Submit
            </Button>
            <Button className="contactBtn2" type="secondary" size="large" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </Spin>
  )
}

export default ContactUs