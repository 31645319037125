
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    fromRoleRights : null
};


const rolestateSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setFromRoleRights: (state, action) => {
      state.fromRoleRights = action.payload;
    }
  },
});

export const { setFromRoleRights } = rolestateSlice.actions;

export default rolestateSlice.reducer;


