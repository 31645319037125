import { Button, Col, Form, Input, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilMail from '@iconscout/react-unicons/icons/uil-envelope';
import UilSubject from '@iconscout/react-unicons/icons/uil-bars';
import UilSchool from '@iconscout/react-unicons/icons/uil-building';
import PasswordStrengthBar from 'react-password-strength-bar';
import { MessageType, showNotification } from '../common/CommonStyle';
import { post } from '../../config/dataService/dataService';

const PasswordChange = ({ onCancel }) => {

  const userId = localStorage.getItem('USER_ID');
  const firstName = localStorage.getItem('FIRST_NAME');
  const lastName = localStorage.getItem('LAST_NAME');
  const email = localStorage.getItem('EMAIL');
  const roleName = localStorage.getItem('ROLE_NAME');
  const userRoleLevelName = localStorage.getItem('USER_ROLE_LEVEL_NAME');
  const fullname = `${firstName} ${lastName}`.trim();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [strongPassword, setStrongPassword] = useState(false);
  const [score, setScore] = useState(0);

  const urls = {
    changePasswordService: 'User/ChangePassword'
  }

  const handleCancel = () => {
    form.resetFields();
    setPassword('');
    setStrongPassword(false);
    setScore(0);
    onCancel();
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordStrengthChange = (score) => {
    setScore(score);
    setStrongPassword(score >= 4);
  };

  const handleChangePassword = async (values) => {
    try {
      setLoading(true);
      if (values.NewPassword !== values.ConfirmPassword) {
        showNotification(MessageType.Error, 'Passwords do not match');
        setLoading(false);
        return;
      }

      // var strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$/;
      const strongPasswordRegex =/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){9,}$/;
      const ischeck=strongPasswordRegex.test(values.NewPassword);
      if (ischeck==false) {
        showNotification(MessageType.Error,'Password must be Strong!!');
        setLoading(false);
        form.resetFields();
        return;
      }
      if (score < 4) {
        showNotification(MessageType.Error,'Password strength is not sufficient!');
        setLoading(false);
        form.resetFields();
        return;
      }

      const data = {
        userId: userId,
        currentPassword: values.CurrentPassword,
        newPassword: values.NewPassword,
        confirmPassword: values.ConfirmPassword
      }
      console.log(data);
      const result = await post(urls.changePasswordService,data);
      if(result.flag){
        showNotification(MessageType.Success,'Password Changed Successfully!');
        form.resetFields();
        handleCancel();
      }else{
        showNotification(MessageType.Error,'Something went wrong!')
      }
    } catch (errorInfo) {
      showNotification(MessageType.CatchError, '');
    }
    setLoading(false);
  }

  return (
    <div>
      <Spin spinning={loading}>
      <Form form={form} onFinish={handleChangePassword} className="contact_style">
        <Row align="middle">
          <Col lg={5} md={9} xs={24}>
            <label className='contactus'>Name</label>
          </Col>
          <Col lg={19} md={15} xs={24}>
            <Form.Item name="Name" className="ninjadash-icon-left">
              <Input prefix={<UilUser />} defaultValue={fullname} readonly="readonly" />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={5} md={9} xs={24}>                                                                 
            <label className='contactus' htmlFor="email">Email Address</label>
          </Col>
          <Col lg={19} md={15} xs={24}>
            <Form.Item className="ninjadash-icon-left" name="Email">
              <Input prefix={<UilMail />} defaultValue={email} readonly="readonly"/>
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={5} md={9} xs={24}>
            <label className="contactus">UserRole Level </label>
          </Col>
          <Col lg={19} md={15} xs={24}>
            <Form.Item name="UserRoleLevel">
              <Input prefix={<UilSubject />} defaultValue={userRoleLevelName} readonly="readonly"/>
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={5} md={9} xs={24}>
            <label className='contactus'>Role Name</label>
          </Col>
          <Col lg={19} md={15} xs={24}>
            <Form.Item className="ninjadash-icon-left" name="UserRoleName">
              <Input prefix={<UilSchool />} defaultValue={roleName} readonly="readonly" />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={5} md={9} xs={24}>
            <label className='required-label contactus'>Current Password</label>
          </Col>
          <Col lg={19} md={15} xs={24}>
            <Form.Item name="CurrentPassword" className="ninjadash-icon-left"
              rules={[{ required: true, message: 'Current Password is required' }]}>
              <Input.Password 
                autoComplete='off' />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={5} md={9} xs={24}>                                                                 
            <label className='required-label contactus'>New Password</label>
          </Col>
          <Col lg={19} md={15} xs={24}>
            <Form.Item className="ninjadash-icon-left" name="NewPassword"
              rules={[
                { required: true, message: 'New Password is required' },
                { min: 9, message: 'Password must be at least 9 characters long!' }]}>
              <Input.Password 
                autoComplete='off' 
                onChange={(e) => {
                  handlePasswordChange(e);
                }} />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={5} md={9} xs={24}>
          </Col>
          <Col lg={19} md={15} xs={24}>
            <PasswordStrengthBar password={password} onChangeScore={handlePasswordStrengthChange} />
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={5} md={9} xs={24}>
            <label className="required-label contactus">Confirm Password</label>
          </Col>
          <Col lg={19} md={15} xs={24}>
            <Form.Item name="ConfirmPassword"
            dependencies={['NewPassword']}
              rules={[
                { required: true, message: 'Confirm Password is required' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('NewPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Password does not match!'));
                  },
                }),]}>
              <Input.Password
               autoComplete='off'
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle">
          <b>Strong Password Requirenments: </b>
          <br />
          <br />
        </Row>
        <Row>
          <span>MUST contain at least 9 characters (12+ recommended) </span>
          <br />
        </Row>
        <Row>
          <span>MUST contain at least one uppercase letter </span>
          <br />
        </Row>
        <Row>
          <span>MUST contain at least one lowercase letter </span>
          <br />
        </Row>
        <Row>
          <span>MUST contain at least one number </span>
          <br />
        </Row>
        <Row>
          <span>MUST contain at least one special character</span>
          <br />
          <br />
        </Row>
        <Row style={{ justifyContent: "end" }}>
          <div className="ninjadash-form-action">
            <Button className="contactBtn1" type="primary" size="large" htmlType="submit">
              Change Password
            </Button>
            <Button className="contactBtn2" type="secondary" size="large" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Row>
      </Form>
    </Spin>
    </div>
  )
}

export default PasswordChange
